import React from 'react';
import tyld from 'assets/images/tyld.svg';
import tyldP from 'assets/images/tyld-primary.svg';
import './index.scss';

function Header() {
  return (
    <header className="Header">
      <div className="Header-logo">
        <img src={tyld} className="Header-logo-img" alt="tyld" />
        <img src={tyldP} className="Header-logo-img-primary" alt="tyld" />
        {/*<Tyld/>*/}
      </div>
      <ul>
        <li><a href="#hunt">Entreprises</a></li>
        <li><a href="#steps">Développeurs</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </header>
  );
}

export default Header;
