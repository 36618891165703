import React from 'react';
import Contact from './Contact';

import welcome from 'assets/images/home.svg';
import tech from 'assets/images/tech.jpg';
import scan from 'assets/images/scan.svg';
import bench from 'assets/images/bench.svg';
import phone from 'assets/images/phone.svg';
import chat from 'assets/images/chat.svg';
import web from 'assets/images/web.svg';
import paris from 'assets/images/paris.svg';

import aws from 'assets/images/icons/amazonwebservices-original.svg';
import css from 'assets/images/icons/css3-original.svg';
import git from 'assets/images/icons/git-original.svg';
import go from 'assets/images/icons/go-original.svg';
import html from 'assets/images/icons/html5-original-wordmark.svg';
import js from 'assets/images/icons/javascript-original.svg';
import laravel from 'assets/images/icons/laravel-plain.svg';
import node from 'assets/images/icons/nodejs-original.svg';
import python from 'assets/images/icons/python-original.svg';
import react from 'assets/images/icons/react-original.svg';
import typescript from 'assets/images/icons/typescript-original.svg';
import webpack from 'assets/images/icons/webpack-original.svg';
import vue from 'assets/images/icons/vuejs-original.svg';

import './index.scss';

function Main() {
  return (
    <main className="Main">
      <section className="Main-section1 d-flex">
        <div className="container d-flex row">
          <div className="Main-section1-description col-md-6">
            <h1 className="fade-in">
              Tech Stars<br/>
              & modern stacks.
            </h1>
            <p className="spartan-semi-bold" className="fade-in delay-3">
              Spécialiste des métiers Techs,<br/>
              Tyld sélectionne les meilleurs profils pour intégrer votre équipe
            </p>
            <a href="#contact">
              <button className="btn btn-lg btn-primary mt-4 fade-in delay-6">Let's go</button>
            </a>
            <div className="mt-4">
              <div>
                <img src={react} className="Main-tech fade-in" alt="react" />
                <img src={node} className="Main-tech fade-in delay-1" alt="node" />
                <img src={aws} className="Main-tech fade-in delay-2" alt="aws" />
                <img src={css} className="Main-tech fade-in delay-3" alt="css" />
                <img src={git} className="Main-tech fade-in delay-4" alt="git" />
                <img src={go} className="Main-tech fade-in delay-5" alt="go" />
                <img src={html} className="Main-tech fade-in delay-6" alt="html " />
                <img src={js} className="Main-tech fade-in delay-7" alt="js" />
                <img src={laravel} className="Main-tech fade-in delay-8" alt="laravel" />
                <img src={python} className="Main-tech fade-in delay-9" alt="python" />
                <img src={typescript} className="Main-tech fade-in delay-10" alt="typescript" />
                <img src={webpack} className="Main-tech fade-in delay-11" alt="webpack" />
                <img src={vue} className="Main-tech fade-in delay-12" alt="vue" />
              </div>
            </div>
          </div>
          <div className="col-md-6 Main-welcome">
            <img src={welcome} className="Main-welcome-img fade-in mb-5 mt-5" alt="welcome" />
          </div>
        </div>
      </section>
      <section id="hunt" className="d-flex">
        <div className="container d-flex row reverse-md align-item-center">
          <img className="col-md-6 m-auto" src={paris} alt="paris" />
          <div className="col-md-6">
            <p>
              <h3>
                Des développeurs testés et approuvés par notre équipe d’experts
              </h3>
              Pour ses entreprises partenaires, TYLD sélectionne les meilleurs profils tech de toute l’Ile-de-France.<br/>
              Nos experts recherchent, questionnent et testent les développeurs avant de les proposer sur une mission qui leur correspond parfaitement.<br/>
            </p>
          </div>
        </div>
      </section>
      <section id="bench" className="d-flex">
        <div className="container row d-flex align-item-center">
          <img className="col-md-6 m-auto" src={bench} alt="bench" />
          <div className="col-md-6">
            <p>
              <h3>
                Un recruteur dédié et à l’écoute de vos projets
              </h3>
              Dès le premier échange, un recruteur vous questionne pour trouver la perle rare qui intégrera votre équipe. Il vous accompagnera tout au long du recrutement et s'assurera de vous trouver le candidat qu'il vous faut.
            </p>
          </div>
        </div>
      </section>
      <section id="phone" className="d-flex">
        <div className="container d-flex align-item-center row reverse-md mb-5">
          <img src={phone} className="col-md-6 m-auto" alt="phone" />
          <div className="col-md-6">
            <p>
              <h3>
                Une collaboration en toute transparence
              </h3>
              Chez TYLD, nous sommes totalement transparents sur les rémunérations de chaque partie et souhaitons rester en contact avec vous tout au long de la mission. De plus, nous ne touchons aucune commission tant que notre développeur n'a pas rejoint votre équipe.
              <div>
                <a href="#contact">
                  <button className="btn btn-lg btn-primary mt-4">Let's go</button>
                </a>
              </div>
            </p>
          </div>
        </div>
      </section>
      <section id="steps" className="d-flex">
        <div className="container align-item-center d-flex row">
          <div className="mb-6">
            <h2>
              Tu es développeur?
            </h2>
          </div>
          <div className="col-md-4">
            <img src={chat} className="" alt="chat" />
            <div className="mt-4 mb-5">
              Un recruteur te contacte pour connaître ton parcours professionnel et le job idéal selon toi.
            </div>
          </div>
          <div className="col-md-4">
            <img src={scan} className="steps-scan-img" alt="scan" />
            <div className="mt-5 mb-5">
              Il analyse ton profil et, selon la mission, réalise un test technique.
            </div>
          </div>
          <div className="col-md-4">
            <img src={web} className="" alt="web" />
            <div className="mt-4 mb-5">
              Ton profil est transmis aux entreprises qui te correspondent et nous assurons l'intégralité du suivi de recrutement.
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="">
        <Contact/>
      </section>
    </main>
  );
}

export default Main;
