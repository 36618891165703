import React from 'react';
import Header from 'components/Header'
import Main from 'components/Main'
import './App.scss';

function App() {
  return (
    <div className="App">
      <Header/>
      <Main/>
    </div>
  );
}

export default App;
