import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    "x-api-key": "KSmfWG0J725xNIEzMaTRfaEVFd7BgK4X7zx9GJmN",
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest'
  }
});

export default axiosInstance;
