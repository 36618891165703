import React, {useState} from 'react';
import Axios from '../../../services/Axios';

const Contact = (props) => {

  const [name,setName] = useState();
  const [contact,setContact] = useState();
  const [message,setMessage] = useState();
  const [messageSent,setMessageSent] = useState();

  const sendEmail = async () => {
    if (!message || !name || !contact) {
      return;
    }
    try {
      const url = "https://jnyk8z0hq1.execute-api.eu-west-3.amazonaws.com/default/sendEmail";
      const data = {
        message,
        name,
        contact
      };
      await Axios.post(url,data);
      setMessageSent(true)
    } catch (e) {
    }
  };

  return (
    <div>
      {!messageSent && <>
        <div className="mb-4">
          Say Hello
        </div>
        <div>
          <input onChange={e=>setName(e.target.value)} name="name" className="form-control" type="text" placeholder="Nom"/>
        </div>
        <div>
          <input onChange={e=>setContact(e.target.value)} name="contact" className="form-control" type="text" placeholder="Téléphone / Mail"/>
        </div>
        <div>
          <textarea onChange={e=>setMessage(e.target.value)} rows="5" className="form-control" defaultValue="Message" />
        </div>
        <div className="text-align-right">
          <button onClick={sendEmail} className="btn btn-lg btn-white mt-4">envoyer</button>
        </div>
      </>
      }
      {messageSent && <div>Merci pour votre message, nous vous contacterons rapidement!</div>}
    </div>
  );
};

export default Contact;
